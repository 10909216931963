.card-content {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #1890ff;
}

.duplicate-row {
    background-color: #ffcccb; /* Light red color */
}
