.card-content {
    font-size: 20px; /* Reduced font size */
    font-weight: bold;
    text-align: center;
    color: #1890ff;
    padding: 5px 0; /* Further reduced padding */
}

.ant-card-body {
    padding: 12px; /* Reduced padding for the card body */
}
